import React from 'react';
import './style.scss';

type ModalParams = {
  handleClose: () => void,
  show: boolean,
  title: string,
  children: React.ReactNode,
  width?: number | string,
  maxHeight?: number | string
}

// component that renders its content in a modal overlay;
// - handleClose - function to call when "close" button is clicked; if not provided, "close" button is omitted
// - show - variable that determines the visibility of the modal dialog; handleClose() should set this variable to "false"
export default function Modal({ handleClose, show, title, children, width, maxHeight }: ModalParams) {
  let style: any = {};
  if (width)
    style.width = width;
  if (maxHeight)
    style.maxHeight = maxHeight;
  return (
    <div className={`modal display-${show ? 'block' : 'none'}`}>
      <section className="modal-main" style={style}>
        <div className="modal-header">
          {handleClose ? <div className="modal-button right" onClick={handleClose}>&times;</div> : ''}
          <h3>{title !== undefined ? title : ''}</h3>
        </div>
        <div className="clear"></div>
        <div className="modal-contents">
          {children}
        </div>
      </section>
    </div>
  );
}
