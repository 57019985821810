// MUI Material v5.;
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const CustomizedToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: 18,
    backgroundColor: "rgba(10, 190, 55, 0.63)",
  },
}));

const longText = `
New: Ticket has been created.
Inprog: Ticket is being reviewed.
Closed: Ticket was closed.
`;

export default function ToolTipStatuses() {
  return (
      <CustomizedToolTip title={longText} placement="right">
        <IconButton>
          <HelpOutlineIcon />
        </IconButton>
      </CustomizedToolTip>
  );
}
