import "./App.css";

// Components.
import Dashboard from "./components/Dashboard";

// Routes.
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Amplify. 
import { Amplify } from 'aws-amplify';
import { Authenticator} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import config from './config';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: 'testApiCall',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      }
    ]
  }
});

export default function App() {

  return (
  <Authenticator className="signIn" hideSignUp={true}>
      <Router>
        <Routes>
            <Route exact path="/" element={<Dashboard />} />
        </Routes>
      </Router>
  </Authenticator>
  );
}
