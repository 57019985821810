// Hooks.
import { useState } from 'react';

// CSS.
import "./styles/Navbar.css";
import "./styles/Form.css"; // Only importing the font from here.

// Amplify.
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

// Table with "TableWithSorting" file.

// MUI Material v5.
import Button from '@mui/material/Button';
import LogoutIcon from '@mui/icons-material/Logout';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import TicketsTable from "./Tickets";
import QnA from "./QnA";
     
function Dashboard({ signOut }) {
 
const [showQA, setShowQA] = useState(false);   

//---------------------------------------------------------------------------------------------------
  return (
    <div>
      <header>
          <nav>
            <div style={{paddingLeft:"2em"}}>
            <h1 style={{color:"black"}}>AGENTS PORTAL</h1>
            </div>
            <div style={{paddingRight:"2em", paddingTop:"1.5em"}}>
            <Button className="signOutButton" style={{color:"black"}} onClick={() => setShowQA(true)} variant="" startIcon={<QuestionAnswerIcon />}>Q&A</Button>
            <Button className="signOutButton" style={{color:"black"}} onClick={signOut} variant="" startIcon={<LogoutIcon />}>Sign Out</Button>
            </div>
          </nav>
      </header>
        {showQA ? <QnA hide={() => setShowQA(false)}/> : ""}
        <TicketsTable />
    </div>
  );
}
// In the App component, the Authenticator component is being used, but on this component I use the withAuthenticator component to be able to log out.
export default withAuthenticator(Dashboard);  