import { useForm } from 'react-hook-form';
import useFetch from '../utils/useFetch';
import Modal from './Modal';
import { useEffect } from 'react';
// Amplify.
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { errorNotification, successNotification } from '../utils/actions';
import ToolTipStatuses from './toolTips/ToolTipStatuses';
import ToolTipSeverities from './toolTips/ToolTipSeverities';
import "./styles/Form.css"

const AddOrEditTicketModal = ({add, hide, editTicket, data, user}) => {

    const { register, handleSubmit, errors, reset } = useForm();

    useEffect(() => reset(data), [data]);
    
    const ticketsdata = useFetch(`/tickets`, {extract: data => data.tickets}); // Only for state variables.

    // Determine if the user logged in has admin rights to change the status.
    var isNotAdmin = false;
    if (user.username !== "TestUser4") {
        isNotAdmin= true;
    } 
  
    if (!ticketsdata.loaded)
      return <Modal handleClose={hide} show={true} title={add ? 'Add Ticket' : 'Edit Ticket'}>
              {ticketsdata.error ? 'Failed to load! ' + ticketsdata.error : 'Loading...'}
            </Modal>;
  
    return <Modal handleClose={hide} show={true} title={add ? 'Add Ticket' : 'Edit Ticket'} width="45em" Height="120px">
      

<form onSubmit={handleSubmit(editTicket)}>
  {add ? '' : <input name="id" className="full" hidden={true} type="text" ref={register} />}

  <div style={ {display:"flex"}}>

    <div style={ {}}>      
          <div style={ {paddingBottom:"2em"}}>
            <label htmlFor="status">Status<ToolTipStatuses /></label>
            <select name="status" ref={register} disabled={isNotAdmin}>
              <option value="New">New</option>
              <option value="Inprog">Inprog</option>
              <option value="Closed">Closed</option>
            </select>
            </div>
        
          <div style={{paddingBottom:"2em"}}>
            <label htmlFor="data.title">Problem Title</label>
            { errors.title && <span className="error">Required!</span>}
            <textarea name="data.title" type="text" ref={register({ required: true, validate: t => (t.trim() != '') })} />
          </div>

          <div style={{paddingBottom:"2em"}}>
            <label htmlFor="data.description">Problem Description</label>
            { errors.description && <span className="error">Required!</span>}
            <textarea name="data.description" type="text" placeholder="Please provide the email of the agent having the problem." ref={register({ required: true, validate: t => (t.trim() != '') })} />
          </div>

          <div style={{paddingBottom:"2em"}}>
            <label htmlFor="data.steps_to_reproduce">Steps to reproduce</label>
            { errors.steps_to_reproduce && <span className="error">Required!</span>}
            <textarea name="data.steps_to_reproduce" type="text" ref={register({ required: true, validate: t => (t.trim() != '') })} />
          </div>
    </div>

    <div  style={ { paddingLeft:"1em"}}>
          <div style={ {paddingBottom:"2em"}}>
            <label htmlFor="severity">Severity<ToolTipSeverities /></label>
            <select name="severity" ref={register}>
              <option value="Medium">Medium</option>
              <option value="High">High</option>
              <option value="Critical">Critical</option>
            </select>
          </div>

          <div style={{paddingBottom:"2em"}}>
            <label htmlFor="data.expected_behaviour">Expected Behaviour</label>
            { errors.expected_behaviour && <span className="error">Required!</span>}
            <textarea name="data.expected_behaviour" type="text" ref={register({ required: true, validate: t => (t.trim() != '') })} />
          </div>

          <div style={{paddingBottom:"2em"}}>
            <label htmlFor="data.resulted_behaviour">Resulted Behaviour</label>
            { errors.resulted_behaviour && <span className="error">Required!</span>}
            <textarea name="data.resulted_behaviour" type="text" ref={register({ required: true, validate: t => (t.trim() != '') })} />
          </div>

          <div>
          <label htmlFor="file">Upload screenshot</label>
          { errors.file && <span className="error">Required!</span>}
          <input type="file" name="data.uploaded_screenshot" ref={register({ required: true})} multiple={true}/>
          </div>
          
          <div style={{float:"right", paddingTop:"4em"}}>
            <button className="addTicketButton">{add ? "Create" : "Edit"}</button>
          </div>

    </div>
  </div>
</form>

</Modal>;
};

  export default withAuthenticator(AddOrEditTicketModal);
