export default {
    apiGateway: {
      REGION: 'eu-central-1',
      URL: ''
    },
    cognito: {
      REGION: 'eu-central-1',
      USER_POOL_ID: 'eu-central-1_dDmxXJN9Y',
      APP_CLIENT_ID: '3u1alf0oaj1lc4vspf9b09ht77',
      IDENTITY_POOL_ID: 'eu-central-1:5c6dadc4-4259-4102-9dcf-5190d4de32a3'
    }
  };
  