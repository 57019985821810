import './actions';
import { GL_ERROR_NOTIFICATION, GL_SUCCESS_NOTIFICATION } from './actions';

export const initialState = {


  error_notification: null,
  success_notification: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GL_ERROR_NOTIFICATION:
      return { ...state, error_notification: action.message };
    case GL_SUCCESS_NOTIFICATION:
      return { ...state, success_notification: action.message };
    default:
      return state;
  }
}
